<template>
  <div class="works">
    <left-menu current="memberDynamic"></left-menu>
    <div class="main">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>成员动态</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider></el-divider>
      <div class="content">
        <div class="formBox">
          <div class="datePickerBox">
            <el-date-picker
              v-model="dateValue"
              type="daterange"
              value-forma=""
              @change="dateChange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>

          <div class="selectBox">
            <el-select
              @change="typeChange"
              value-key="id"
              v-model="params.type"
              placeholder="请选择"
            >
              <el-option :value="0" :key="0" label="发行申请" />
            </el-select>
          </div>

          <div class="selectBox">
            <el-select
              @change="userChange"
              value-key="id"
              v-model="params.uid"
              placeholder="请选择"
            >
              <el-option value="" key="" label="操作人" />
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.nickName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <el-button
              @click="resetFn"
              class="reset btn"
          >重置</el-button
          >
          <el-button @click="submitFn" class="submit btn" type="primary"
            >筛选</el-button
          >
        </div>
        <div class="dynamicItemBox" >
          <div
            class="dynamicItem"
            v-for="(item, index) in dataList"
            :key="index"
          >
            <dynamicItem :userList="userList" :itemInfo="item"></dynamicItem>
          </div>
        </div>

        <div>
          <div v-if="hasNextPage && dataList.length != 0" @click="moreFn" class="more">
          <span>加载更多</span><i class="el-icon-arrow-down"></i>
        </div>
        <div
          v-if="!hasNextPage && dataList.length != 0"
          style="cursor: auto; opacity: 0.8"
          class="more"
        >
          <span>没有更多了</span>
        </div>
        </div>

        <div class="null" v-if="total == 0 && dataList.length == 0 && !dataLoading">暂无数据</div>
        <div class="null" v-loading="dataLoading" v-if="dataLoading"></div>


      </div>
    </div>
  </div>
</template>
<script>
import leftMenu from "@/components/LeftMenu";
import dynamicItem from "./components/dynamicItem.vue";
import { getUserDynamics, getUserList } from "@/api/issue/";
import moment from "moment";
const PAGESIZE = 10;
export default {
  name: "member-management",
  components: {
    leftMenu,
    dynamicItem,
  },
  mounted() {
    moment.locale("zh-cn");
  },
  data() {
    return {
      moment,
      hasNextPage:true,
      dataLoading:true,
      loading:false,
      total:-1,
      params: {
        endTime: '',
        pageNum: 1,
        pageSize: PAGESIZE,
        startTime: '',
        type: 0,
        uid: '',
      },
      userList: [],
      dateValue: [],
      dataList: [],
      oldList : []
    };
  },

  created() {
    this.getUserListFn();
    this.getListFn();
  },

  methods: {
    dateChange(date) {
      if (date) {
        this.params.startTime = `${date[0]} 00:00:00`;
        this.params.endTime = `${date[1]} 23:59:59`;
      } else {
        this.params.startTime = '';
        this.params.endTime = '';
      }
    },
    submitFn() {
      this.params.pageNum = 1
      this.getListFn(true);
    },
    moreFn() {
      if (this.hasNextPage) {
        console.log("查看更多");
        this.getListFn();
      }
    },
    resetFn() {
      this.params = {
        endTime: "",
        pageNum: 1,
        pageSize: PAGESIZE,
        startTime: "",
        type: 0,
        uid: "",
      };
      this.dateValue = [];
      this.getListFn(true);
    },
    //动态列表
    getListFn(reload = false) {
      if(reload){
        this.dataLoading = true
        this.oldList = []
        this.dataList = []
      }
      if(this.loading){
        return
      }
      this.loading = true
      getUserDynamics(this.params).then((res) => {
        if (res.code == 0) {
          if (res.data) {
            let { list,hasNextPage,total } = res.data;
            if(hasNextPage){
              this.params.pageNum++
            }
            this.total = total
            this.hasNextPage = hasNextPage
            this.oldList.push(...list)
            this.dataList = this.sortData(this.oldList)
          } else {
            this.oldList = []
            this.dataList = []
            this.hasNextPage = false
            this.total = 0
            console.log("暂无数据");
          }
        }
      }).finally(()=>{
        this.loading = false
        this.dataLoading = false
      })
      .catch(()=>{
        this.loading = false
        this.dataLoading = false
      });
    },

    sortData(data) {
      const groupBy = (array, f) => {
        let groups = {};
        array.forEach((o) => {
          let group = JSON.stringify(f(o));
          groups[group] = groups[group] || [];
          groups[group].push(o);
        });
        return Object.keys(groups).map((group) => {
          return groups[group];
        });
      };
      const sorted = groupBy(data, (item) => {
        return item.createTimeStr;
      });
      return sorted;
    },

    //操作人列表
    getUserListFn() {
      getUserList().then((res) => {
        if (res.code == 0) {
          this.userList = res.data;
        }
      });
    },

    //选择操作人
    userChange(data) {
      this.params.uid = data;
    },

    //选择类型
    typeChange(data) {
      this.params.type = data;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/works.scss";

.content {
  display: flex;
  flex-direction: column;
  .null{
    color: #aaa;
    font-size: 14px;
    text-align: center;
    padding-top: 300px;
  }
  .more {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #aaa;
    font-size: 14px;
    cursor: pointer;
  }
  .dynamicItemBox {
    .dynamicItem:last-child {
      &::after {
        display: none !important;
      }
    }
  }
  .dynamicItem {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      width: 100%;
      height: 1px;
      border-bottom: 1px dashed #aaaaaa;
      transform: scaleY(0.5);
    }
  }

  .formBox {
    display: flex;
    align-items: center;
    .btn {
      width: 96px;
      height: 40px;
      border-radius: 2px;
      font-size: 14px;
    }
    ::v-deep .el-input__inner {
      border: 1px solid #e5e5e5;
      border-radius: 3px;
      padding: 0 10px;
    }
    .datePickerBox {
      height: 40px;
      width: 386px;
      margin-right: 26px;
      ::v-deep .el-input__inner {
        height: 40px;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 3px;
        padding: 0 10px;
        width: 384px;
      }
      ::v-deep .el-date-editor{
          .el-input__icon{
            line-height: 38px;
          }
      }
    }
    .selectBox {
      margin-right: 26px;
      ::v-deep .el-select {
        width: 260px;
      }
    }
  }
}
</style>

