<template>
  <div v-if="datas" class="item">
    <div class="title">{{ moment(datas[0].createTimeStr).format('YYYY年MM月DD日') }}</div>
    <div v-for="(item,index) in datas" :key="index" class="con">
      <div class="conLeft">
        <div class="time">{{ moment(item.createTime).format('LT') }}</div>
      <el-image
        style="width: 40px; height: 40px; border-radius: 50%"
        class="avatar"
        :src="avatarFun(item.uid)"
        fit="cover"
      >
      </el-image>
      <div class="name">{{ nameFun(item.uid) }}</div>
      </div>
      <div class="detailBox">
        <div class="detail">
          <span>{{typeFun(item.type)}}：专辑</span>
          <span>《{{ item.albumName }}》</span>
          <div v-if="item.updateData && item.updateData.length>0" class="detailBtn" @click="detailFun(index)">
            <span style="padding-right:4px">查看详情</span>
            <i v-if="item.isActive" class="el-icon-arrow-up"></i>
            <i v-else class="el-icon-arrow-down"></i>
          </div>
        </div>
        <el-collapse accordion>
          <el-collapse-item ref="collapseItem">
            <div>
              <div class="collapseItem" v-for="(itemm,indexx) in item.updateData" :key="indexx">
                <span>{{formatName(itemm.column_name)}} {{typeFun(itemm.type)}}</span>
                <span>{{typeFun(itemm.type)}}前：{{itemm.origin_text}}</span>
                <span style="padding-left:50px;color:#333333">{{typeFun(itemm.type)}}后：{{itemm.dest_text}}</span>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment"
export default {
  props: {
    itemInfo: {
      type: Array,
      default: () => {
        return [];
      },
    },
    userList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      moment,
      isActive:false,
      datas:null
    };
  },
mounted(){
  this.datas = this.itemInfo
},
watch:{
   "itemInfo":{
          handler:function(val){
            this.datas = val
          },
          deep:true
       }
},

  methods: {
    formatName(name){
      return name.substr( name.indexOf("-") + 1,name.length);
    },

    nameFun(uid){
      let name = ''
      this.userList.forEach(item => {
        if(item.id == uid){
          name = item.nickName
        }
      });
      return name
    },

    avatarFun(uid){
      let avatar = ''
      this.userList.forEach(item => {
        if(item.id == uid){
          avatar = item.avatar
        }
      });
      return avatar
    },


    typeFun(type){
      let t = ''
      if(type == 0){
        t = '新增'
      }else if(type == 1){
        t = '修改'
      }else if(type == 2){
        t = '撤销'
      }else if(type == 3){
        t = '删除'
      }else if(type == 4){
        t = '新增草稿'
      }else if(type == 5){
        t = '修改草稿'
      }else{
        t = ''
      }
      return t
    },
    detailFun(index) {
      this.$refs.collapseItem[index].handleHeaderClick();
      this.$set(this.datas[index],'isActive',this.$refs.collapseItem[index].isActive)
    },
  },
};
</script>

<style scoped lang="scss">
.item {
  background: #fff;
  display: flex;
  flex-direction: column;


  ::v-deep .el-collapse-item__content{
    padding-bottom: 0;
  }
  .title {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-top: 30px;
    margin-bottom: 25px;
  }
  .conLeft{
    display: flex;
    align-items: center;
  }
  .con {
    display: flex;
    margin-bottom: 30px;
    align-items: flex-start;
    .avatar {
      margin: 0 10px 0 20px;
    }
    .time{
        display: flex;
        align-items: center;
        height: 50px;
        font-size: 12px;
        color: #999;
      }
      .name{
        display: flex;
        align-items: center;
        height: 50px;
        color: #999;
        font-size: 14px;
      }
    .detailBox {
      margin-top: 14px;
      flex: 1;
      .detail {
        display: flex;
        margin-left: 60px;
        margin-bottom: 16px;
        font-size: 14px;
        color: #666;
        .detailBtn {
          color: #FF477E;
          margin-left: 19px;
          font-size: 14px;
          cursor: pointer;
        }
      }
      .collapseItem{
        margin-bottom: 12px;
        margin-left: 60px;
        span{
          margin-right: 10px;
          color: #999;
          font-size: 12px;
        }
      }
    }
  }
  ::v-deep .el-collapse {
      border: none !important;
    }
    ::v-deep .el-collapse-item {
      div {
        border: none !important;
      }
      > div {
        &:first-child {
          display: none;
        }
      }
    }
}
</style>

